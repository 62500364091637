<template>
   <ObiTabBar class="language-tab">
      <template #tabs>
         <ObiTabs :items="languages" v-model="selectedLanguage" />
      </template>
      <template #default>
         <ObiInput
            v-if="reverse"
            clean
            v-model="inputValue[name][selectedLanguage]"
            @change="onChanged"
            @input="onInput"
         />
         <ObiInput v-else clean v-model="inputValue[selectedLanguage][name]" @change="onChanged" @input="onInput" />
      </template>
   </ObiTabBar>
</template>

<script>
export default {
   name: 'MultiLanguageInput',

   props: {
      value: {
         default: () => ({}),
      },
      name: {
         default: () => ({}),
      },
      reverse: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
   },

   data() {
      return {
         selectedLanguage: 'tr',
         languages: [
            { id: 'tr', label: 'Türkçe' },
            { id: 'en', label: 'İngilizce' },
            { id: 'de', label: 'Almanca' },
            { id: 'ru', label: 'Rusça' },
            { id: 'ar', label: 'Arapça' },
         ],
      };
   },

   watch: {
      inputValue(val) {
         this.$emit('input', val);
      },
   },

   methods: {
      onChanged() {
         this.$emit('change', this.inputValue);
      },
      onInput() {
         this.$emit('input', this.inputValue);
      },
   },
};
</script>

<style scoped>
/deep/ .obi-tb-top {
   padding: 0 !important;
}
/deep/ .obi-tb-body {
   padding: 0 !important;
   padding-top: 10px !important;
}
</style>
